import React from "react"
import ReactTooltip from "react-tooltip"

const DarkLightModeToggle = ({ darkMode }) => {
  const themeToggle = {
    display: "flex",
    border: "none",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    width: "32px",
    height: "32px",
    marginLeft: "3rem",
  }

  const themeToggleIcon = {
    fontSize: "16px",
  }

  return (
    <>
      <button
        type="button"
        data-toggle="tooltip"
        data-placement="bottom"
        className="theme-toggle"
        style={themeToggle}
        data-tip={darkMode ? "Light Theme" : "Dark Theme"}
      >
        <i
          className={`theme-toggle-icon fas f ${
            darkMode ? "fa-sun" : "fa-moon"
          }`}
          style={themeToggleIcon}
        ></i>
      </button>
      <ReactTooltip place="bottom" />
    </>
  )
}

export default DarkLightModeToggle
