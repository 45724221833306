import { Button, Modal } from "react-bootstrap"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { gql, useMutation } from "@apollo/client"
import Alert from "react-bootstrap/Alert"

const RazrooSubscribeButtonDialog = () => {
  const SUBSCRIBE_TO_RAZROO_NEWSLETTER = gql`
    mutation createSubscribe($input: createSubscribeInput) {
      createSubscribe(input: $input) {
        subscribe {
          email
          lastName
          firstName
        }
      }
    }
  `

  const [subscribeToRazrooNewsletter, { data }] = useMutation(
    SUBSCRIBE_TO_RAZROO_NEWSLETTER
  )

  const [show, setShow] = useState(false)
  const [alert, setAlertShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleAlertShow = () => setAlertShow(true)
  const handleAlertHide = () => setAlertShow(false)

  const { handleSubmit, register, errors } = useForm()

  const onShowAlert = () => {
    handleAlertShow()
    window.setTimeout(() => {
      handleAlertHide()
    }, 3000)
  }
  const onSubmit = values => {
    subscribeToRazrooNewsletter({
      variables: {
        input: {
          data: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            listId: "f014cd50-8499-40d8-a981-987237ae6a52",
          },
        },
      },
    })
    handleClose()
    onShowAlert()
  }

  const alertStyling = {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    padding: "0",
    margin: "0",
    textAlign: "center",
  }

  return (
    <>
      <Button className="btn btn-transparent-primary" onClick={handleShow}>
        Subscribe to Razroo
      </Button>

      {alert && data && (
        <Alert show={alert} variant="success" style={alertStyling}>
          <Alert.Heading>
            {data.createSubscribe.subscribe.firstName}{" "}
            {data.createSubscribe.subscribe.lastName} you are subscribed!
          </Alert.Heading>
        </Alert>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Subscribe to the Razroo Newsletter!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form
            onSubmit={e => {
              e.preventDefault()
              handleSubmit(onSubmit)
            }}
          >
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input
                name="email"
                id="email"
                className="form-control"
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
              />
              <small>{errors.email && errors.email.message}</small>
            </div>

            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                name="firstName"
                id="firstName"
                className="form-control"
                ref={register({
                  validate: value => value !== "admin" || "Nice try!",
                })}
              />
              <small>{errors.firstName && errors.firstName.message}</small>
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                name="lastName"
                id="lastName"
                className="form-control"
                ref={register({
                  validate: value => value !== "admin" || "Nice try!",
                })}
              />
              <small>{errors.lastName && errors.lastName.message}</small>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} type="submit">
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit(onSubmit)}>
            Subscribe to Razroo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RazrooSubscribeButtonDialog
