import React from "react"
import { Link } from "gatsby"
import DarkLightModeToggle from "../DarkLightModeToggle"
import RazrooSubscribeButtonDialog from "../RazrooSubscribeButtonDialog"
import { gql, useMutation } from "@apollo/client"
import { useForm } from "react-hook-form"

const Example = ({ darkModeToggle, darkMode }) => {
  const SUBSCRIBE_TO_RAZROO_NEWSLETTER = gql`
    mutation createSubscribe($input: createSubscribeInput) {
      createSubscribe(input: $input) {
        subscribe {
          email
          lastName
          firstName
        }
      }
    }
  `

  const [subscribeToRazrooNewsletter, { data }] = useMutation(
    SUBSCRIBE_TO_RAZROO_NEWSLETTER
  )
  const { handleSubmit, register, errors } = useForm()
  const onSubmit = values => {
    subscribeToRazrooNewsletter({
      variables: {
        input: {
          data: {
            email: values.email,
            listId: "f014cd50-8499-40d8-a981-987237ae6a52",
          },
        },
      },
    })
  }

  const menuText = {
    color: "#ffffff",
  }

  return (
    <header>
      <nav className="navbar navbar-expand-md">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            {/*<img src={Logo} alt="Logo" />*/}
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="446.8px"
              height="116.1px"
              viewBox="0 0 446.8 116.1"
              style={{ enableBackground: "new 0 0 446.8 116.1" }}
            >
              <g id="Icon">
                <g>
                  <g>
                    <path
                      fill="#FF5733"
                      d="M38.6,77.3v-7.6c17.1,0,31.1-13.9,31.1-31.1S55.8,7.6,38.6,7.6S7.6,21.5,7.6,38.6v30.9l25.6,0.1l0,7.6
                                        L0,77.1V38.6C0,17.3,17.3,0,38.6,0C60,0,77.3,17.3,77.3,38.6S60,77.3,38.6,77.3z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      fill="#FF5733"
                      d="M24.8,116.1H0V82.7h7.6v25.9h9.7l0-69.7c0-11.8,9.6-21.4,21.4-21.4c11.8,0,21.4,9.6,21.4,21.4
                                        s-9.6,21.4-21.4,21.4v-7.6c7.6,0,13.8-6.2,13.8-13.8s-6.2-13.8-13.8-13.8s-13.8,6.2-13.8,13.8L24.8,116.1z"
                    />
                  </g>
                </g>
                <path
                  fill="#FF5733"
                  d="M70.4,61.9c-9.7-9.4-25.9-9.3-27.2-9.3h-4.6v7.6h4.6l0.1,0c0.1,0,14.2-0.2,21.8,7.1c3.1,3.1,4.7,7,4.7,12v29.3
                                H58.8l0-24.7c0-3.8-1.3-7-3.8-9.5c-4.9-4.8-12.8-4.9-13.9-4.8h-2.4v7.6h2.5l0.1,0c1.5,0,6.1,0.4,8.5,2.7c1,1,1.5,2.3,1.5,4l0,28.5
                                v3.8h26v-3.8v-3.8V79.3C77.3,72.3,75,66.4,70.4,61.9z"
                />
              </g>
              <g id="Text">
                <g>
                  <path
                    fill="currentColor"
                    d="M174.2,46.3c0,2.8-0.4,5.5-1.2,8.1c-0.8,2.6-1.9,4.9-3.2,7.1c-1.4,2.2-3.1,4.1-5,5.9c-2,1.8-4.2,3.2-6.6,4.3l16,24.9h-16
                                    l-14.2-22.1h-9.2l5.5-13.4h5.8c4,0,7.5-1.5,10.4-4.4c1.3-1.4,2.4-3,3.1-4.8s1.1-3.7,1.1-5.6c0-1.9-0.4-3.8-1.1-5.6
                                    c-0.7-1.8-1.8-3.4-3.1-4.7c-2.9-2.9-6.4-4.4-10.4-4.4h-15.9V39V61v13.4v22.1h-13.4V74.5V39V18.1h29.3c3.9,0,7.5,0.7,10.9,2.2
                                    c3.4,1.5,6.4,3.5,8.9,6c2.5,2.5,4.6,5.5,6,8.9C173.4,38.7,174.2,42.4,174.2,46.3z"
                  />
                  <path
                    fill="currentColor"
                    d="M235.4,40.4v56.1H222v-28c0-4.5-1.6-8.3-4.7-11.4c-3.1-3.1-6.9-4.7-11.4-4.7c-2.2,0-4.2,0.4-6.2,1.2c-2,0.8-3.7,2-5.2,3.5
                                    c-3.1,3.1-4.7,6.9-4.7,11.4c0,4.4,1.6,8.2,4.7,11.3c1.5,1.6,3.2,2.7,5.2,3.5c2,0.8,4,1.2,6.2,1.2c2.2,0,4.3-0.4,6-1.1l4.8,12
                                    c-3.4,1.7-7.4,2.6-12,2.6c-4.1,0-7.9-0.8-11.4-2.3c-3.5-1.5-6.5-3.6-9-6.3c-2.5-2.7-4.5-5.8-5.9-9.4c-1.4-3.6-2.1-7.4-2.1-11.4
                                    c0-4.1,0.7-8,2.1-11.5c1.4-3.6,3.4-6.7,5.9-9.4c2.5-2.7,5.5-4.8,9-6.3c3.5-1.5,7.3-2.3,11.4-2.3c3.7,0,6.9,0.6,9.7,1.7
                                    c2.8,1.1,5.3,2.5,7.5,4.3v-4.5H235.4z"
                  />
                  <path
                    fill="currentColor"
                    d="M288.8,49.7l-27,33.4h25.6v13.4h-36.5H241v-9l27-33.4h-24.6V40.5h35.8v0.1h9.5V49.7z"
                  />
                  <path
                    fill="currentColor"
                    d="M314.9,40.5c2.6-1.1,5.7-1.7,9-1.7v13.4c-4.5,0-8.3,1.6-11.4,4.7c-3.1,3.3-4.7,7.1-4.7,11.4v28.1h-13.4V68.4v-28h13.4v4.4
                                    C309.9,43.1,312.3,41.7,314.9,40.5z"
                  />
                  <path
                    fill="currentColor"
                    d="M353.6,39c4.1,0,8,0.8,11.5,2.3c3.6,1.5,6.7,3.6,9.4,6.3c2.7,2.7,4.8,5.8,6.3,9.4c1.5,3.6,2.3,7.4,2.3,11.5
                                    c0,4-0.8,7.8-2.3,11.4c-1.5,3.6-3.6,6.7-6.3,9.4c-2.7,2.7-5.8,4.8-9.4,6.3c-3.6,1.5-7.4,2.3-11.5,2.3c-4.1,0-8-0.8-11.5-2.3
                                    s-6.7-3.6-9.4-6.3s-4.8-5.8-6.3-9.4c-1.5-3.6-2.3-7.4-2.3-11.4c0-4.1,0.8-8,2.3-11.5c1.5-3.6,3.6-6.7,6.3-9.4
                                    c2.7-2.7,5.8-4.8,9.4-6.3C345.6,39.7,349.5,39,353.6,39z M353.6,52.4c-4.5,0-8.3,1.6-11.4,4.7c-3.1,3.1-4.7,6.9-4.7,11.4
                                    c0,4.4,1.6,8.2,4.7,11.3c1.5,1.6,3.2,2.7,5.2,3.5c2,0.8,4,1.2,6.2,1.2c2.2,0,4.2-0.4,6.2-1.2c1.9-0.8,3.7-2,5.3-3.5
                                    c3.1-3.1,4.7-6.9,4.7-11.3c0-4.5-1.6-8.3-4.7-11.4C361.7,54,357.9,52.4,353.6,52.4z"
                  />
                  <path
                    fill="currentColor"
                    d="M417.2,39c4.1,0,8,0.8,11.5,2.3c3.6,1.5,6.7,3.6,9.4,6.3c2.7,2.7,4.8,5.8,6.3,9.4c1.5,3.6,2.3,7.4,2.3,11.5
                                    c0,4-0.8,7.8-2.3,11.4c-1.5,3.6-3.6,6.7-6.3,9.4c-2.7,2.7-5.8,4.8-9.4,6.3c-3.6,1.5-7.4,2.3-11.5,2.3c-4.1,0-8-0.8-11.5-2.3
                                    s-6.7-3.6-9.4-6.3c-2.7-2.7-4.8-5.8-6.3-9.4c-1.5-3.6-2.3-7.4-2.3-11.4c0-4.1,0.8-8,2.3-11.5c1.5-3.6,3.6-6.7,6.3-9.4
                                    c2.7-2.7,5.8-4.8,9.4-6.3C409.3,39.7,413.1,39,417.2,39z M417.2,52.4c-4.5,0-8.3,1.6-11.4,4.7c-3.1,3.1-4.7,6.9-4.7,11.4
                                    c0,4.4,1.6,8.2,4.7,11.3c1.5,1.6,3.2,2.7,5.2,3.5c2,0.8,4,1.2,6.2,1.2c2.2,0,4.2-0.4,6.2-1.2c1.9-0.8,3.7-2,5.3-3.5
                                    c3.1-3.1,4.7-6.9,4.7-11.3c0-4.5-1.6-8.3-4.7-11.4C425.4,54,421.5,52.4,417.2,52.4z"
                  />
                </g>
              </g>
            </svg>
          </Link>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <RazrooSubscribeButtonDialog />
              {/*<Link to="#" className="btn btn-transparent-primary">*/}
              {/*Subscribe to Razroo*/}
              {/*</Link>*/}
            </li>
            <li className="nav-item">
              <a href="mailto:info@razroo.com" className="btn btn-primary">
                Get In Touch
              </a>
            </li>
            {darkModeToggle && (
              <li className="nav-item">
                <DarkLightModeToggle />
              </li>
            )}

            <li className="nav-item">
              <button className="menu-toggler" data-toggle="menu">
                Menu
                <div className="menu-toggler-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <nav className="menu">
        <div className="menu-table">
          <div className="menu-cell">
            <div className="menu-content">
              <div className="menu-header">
                <ul className="menu-list-inline">
                  <li>
                    <button className="menu-close-button" data-dismiss="menu">
                      <div className="menu-close"></div>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="menu-body">
                <ul className="menu-list menu-list--side">
                  <li>
                    <Link
                      to="https://www.razroo.com"
                      className="menu-list__link"
                    >
                      Product
                    </Link>
                  </li>
                  <li>
                    <Link to="/about/" className="menu-list__link">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="menu-list__link">
                      Articles
                    </Link>
                  </li>
                  <li>
                    <Link to="/razaroons/" className="menu-list__link">
                      Razaroons
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="menu-footer">
                {!data && (
                  <form
                    className="form-subscribe"
                    onSubmit={e => {
                      e.preventDefault()
                      handleSubmit(onSubmit)
                    }}
                  >
                    <input
                      name="email"
                      id="menu-email"
                      className="form-control"
                      placeholder="Please enter your email address"
                      ref={register({
                        required: "Required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address",
                        },
                      })}
                    />
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className="btn btn-primary"
                    >
                      Subscribe to Razroo
                    </button>
                  </form>
                )}
                {data && (
                  <div>
                    <h4 style={menuText}>Subscribed!</h4>
                  </div>
                )}
                <ul className="menu-list-inline">
                  <li>
                    <i style={menuText} className="far fa-envelope"></i>{" "}
                    <a style={menuText} href="mailto:info@razroo.com">
                      info@razroo.com
                    </a>
                  </li>
                  <li className="ml-auto">
                    <ul className="menu-social">
                      <li>
                        <a
                          href="http://www.facebook.com/razroo15"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://twitter.com/_razroo"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://www.linkedin.com/company/razroo"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Example
