import React from "react"
import Logo from "../../../images/logo.svg"
import parse from "html-react-parser"
import { graphql, Link, useStaticQuery } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      strapiGlobalData {
        footerText
      }
    }
  `)
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <Link to="/" className="footer-brand">
              <img src={Logo} alt="footer" />
            </Link>
            <div className="slogan">
              <p>{parse(data.strapiGlobalData.footerText)}</p>
            </div>
          </div>
          <div className="col offset-lg-1">
            <h6 className="footer-title">About</h6>
            <ul className="footer-links">
              <li>
                <Link to="/product/">Product</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h6 className="footer-title">Info</h6>
            <ul className="footer-links">
              <li>
                <Link to="/careers/">Careers</Link>
              </li>
              <li>
                <Link to="/newsletters/">Newsletters</Link>
              </li>
              <li>
                <Link to="/company-hours/">Company Hours</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h6 className="footer-title">Legal</h6>
            <ul className="footer-links">
              <li>
                <Link to="#">License</Link>
              </li>
              <li>
                <Link to="/privacy-policy/">Privacy Policy</Link>
              </li>
              <li>
                <span className="footer-tiny-text">
                  ©2024 Razroo LLC. All Rights Reserved
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
